(function($) {

	/**
	 * initializeBlock
	 *
	 * Adds custom JavaScript to the block HTML.
	 *
	 * @date    15/4/19
	 * @since   1.0.0
	 *
	 * @param   object $block The block jQuery element.
	 * @param   object attributes The block attributes (only available when editing).
	 * @return  void
	 */


	var initializeBlock = function($block) {
		//console.log("BLOCK | Init | ", "Maps")

        function getStyle() {
            //console.log("GOOGLE MAP GET LOCATIONS")
            $.ajax({
                dataType: 'json',
                url: app.templateUrl + '/inc/google-maps-style.json'
            }).done(function(response) {
                //console.log("AJAX DATA", response);
                google.maps.event.addDomListener(window, 'load', init(response));
            }).fail(function(response) {
                //alert( response.responseJSON.message );
            }).always(function() {
                //console.log("AJAX DONE");
            });
        }
        getStyle()


		//google.maps.event.addDomListener(window, 'load', init);
		var map;

		function init(styledata) {
            console.log("LEAFLET MAP INIT", styledata)

			var mapboxTiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
		
			var map = L.map('map', {scrollWheelZoom: false})
				.addLayer(mapboxTiles)
				.setView([40.72332345541449, -73.99], 10);

            //Functions
			function addMarkers(locations) {
				var latlngs = []
				console.log("add: ", locations)
                var i;
				for (i = 0; i < locations.length; i++) {
                    //location variables
                    var name = locations[i].acf.name;
                    var country = locations[i].acf.country;
                    var country_flag = locations[i].acf['country_flag'];
                    var image = locations[i].acf.image ? locations[i].acf.image.sizes.thumbnail : '';
                    var location_link = locations[i].acf['location_link'];
                    var map_location = locations[i].acf['map_location'];
                    var map_icon = locations[i].acf['map_icon'];
					var icon_custom = false;
					if(map_icon){
						icon_custom = L.icon({
							iconUrl: map_icon.url,
							iconSize: [50, 58], // size of the icon
							iconAnchor: [20, 58], // changed marker icon position
							popupAnchor: [0, -60], // changed popup position
						  });
					}

					// create popup contents
					var customPopup = 
                    "<div id='iw-inner'>"+
                    "<div class='image'><img src='"+image+"' /></div>"+
                    "<div class='iw-content'>"+
                    "<div class='name'>" + name + "</div>"+
                    "<div class='country'>" + country + "</div>"+
                    "<a class='btn link' href='" + location_link + "'>View Factory</a>"+
                    "</div>"+
                    "</div>";

					// specify popup options
					var customOptions = {
						maxWidth: "auto", // set max-width
						className: "customPopup", // name custom popup
					};

					if (map_location) {
						//console.log("adding: ", map_location.lat, map_location.lng)
						//add variables to marker
						if(map_icon){
							var marker = L.marker([map_location.lat, map_location.lng], {
								icon: icon_custom,
							  }).bindPopup(customPopup, customOptions).addTo(map);
						}

						latlngs.push([map_location.lat, map_location.lng])
					}
                }
                //adjust bounds
                map.fitBounds(latlngs);
                //adjust zoom so icons show
                // map.setZoom(6);
			}

			function recenterMap(latlng) {
				map.panTo(latlng);
			}

			function getLocations() {
				//console.log("GOOGLE MAP GET LOCATIONS")
				$.ajax({
					url: '/wp-json/custom/v1/locations/',
					method: 'GET', // POST means "add friend" for example.
					async: false,
					beforeSend: function(xhr) {},
					data: {}
				}).done(function(response) {
					//console.log("AJAX DATA", response);
					addMarkers(response);
				}).fail(function(response) {
					//alert( response.responseJSON.message );
				}).always(function() {
					//console.log("AJAX DONE");
				});
			}

			 //add locations
			 getLocations();

/*
            //set variables
            var infoWindows = [];
            //map config
			var mapOptions = {
				center: new google.maps.LatLng(15.7581194, 101.4037577),
				zoom: 5,
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.DEFAULT,
				},
				disableDoubleClickZoom: true,
				mapTypeControl: false,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				},
				scaleControl: true,
				scrollwheel: false,
				panControl: true,
				streetViewControl: false,
				draggable: true,
				overviewMapControl: true,
				overviewMapControlOptions: {
					opened: false,
				},
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: styledata
            }
            //set element and create map
            var mapElement = document.getElementById('mapapi');
            var map = new google.maps.Map(mapElement, mapOptions);
            var bounds = new google.maps.LatLngBounds();
			
            //Functions
			function addMarkers(locations) {

                var i;
				for (i = 0; i < locations.length; i++) {
                    //location variables
                    var name = locations[i].acf.name;
                    var country = locations[i].acf.country;
                    var country_flag = locations[i].acf['country_flag'];
                    var image = locations[i].acf.image.sizes.thumbnail;
                    var location_link = locations[i].acf['location_link'];
                    var map_location = locations[i].acf['map_location'];
                    var LatLng = new google.maps.LatLng(map_location.lat, map_location.lng);
                    var map_icon = locations[i].acf['map_icon'];
                    var icon = {
                        url: map_icon.url, // url
                        scaledSize: new google.maps.Size(70, 70), // scaled size
                        origin: new google.maps.Point(0,0), // origin
                        anchor: new google.maps.Point(35, 70) // anchor
					};

                    //add variables to marker
					var marker = new google.maps.Marker({
                        position: LatLng,
                        icon: icon,
						map: map,
						name: name,
						country: country,
						country_flag: country_flag,
						image: image,
                        location_link: location_link,
                        map_location: map_location,
                        map_icon: map_icon,
						animation: google.maps.Animation.DROP
					});
                    bounds.extend(LatLng);
					bindInfoWindow(marker, map, name, country, country_flag, image, location_link, map_location, map_icon);
					
                }
                //adjust bounds
                map.fitBounds(bounds);
                //adjust zoom so icons show
                map.setZoom(map.getZoom() - 1);
			}
			

			function getLocations() {
				//console.log("GOOGLE MAP GET LOCATIONS")
				$.ajax({
					url: '/wp-json/custom/v1/locations/',
					method: 'GET', // POST means "add friend" for example.
					async: false,
					beforeSend: function(xhr) {},
					data: {}
				}).done(function(response) {
                    //console.log("AJAX DATA", response);
                    addMarkers(response);
				}).fail(function(response) {
					//alert( response.responseJSON.message );
				}).always(function() {
					//console.log("AJAX DONE");
				});
			}


			function closeAllInfoWindows() {
				for (var i = 0; i < infoWindows.length; i++) {
					infoWindows[i].close();
				}
			}

			function bindInfoWindow(marker, map, name, country, country_flag, image, location_link, map_location, map_icon) {
				var infoWindowVisible = (function(enabled) {
					if (enabled == null) {
						enabled = false;
					}
					var currentlyVisible = enabled;
					return function(visible) {
						if (visible !== undefined) {
							currentlyVisible = visible;
						}
						return currentlyVisible;
					};
				}());

                var html = 
                    "<div id='iw-inner'>"+
                    "<div class='image'><img src='"+image+"' /></div>"+
                    "<div class='iw-content'>"+
                    "<div class='name'>" + name + "</div>"+
                    "<div class='country'>" + country + "</div>"+
                    "<a class='btn link' href='" + location_link + "'>View Factory</a>"+
                    "</div>"+
                    "</div>";
				var iw = new google.maps.InfoWindow({
					maxWidth: 350,
					content: html
				});
				infoWindows.push(iw);
				google.maps.event.addListener(marker, 'click', function() {
					closeAllInfoWindows();
                    setTimeout(function(){ 
                        //map.setZoom(10);
                        map.fitBounds(bounds); 
                        //console.log("zoom out");
                    }, 100);
                    setTimeout(function(){ 
                        map.panTo(marker.getPosition()); 
                        //console.log("Pan " + posi);
                    }, 500);
                    setTimeout(function(){ 
                        map.setZoom(7);
                        //console.log("zoom in");
                    }, 1000);
                    setTimeout(function(){ 
                        iw.setOptions({maxWidth: 350})
                        iw.open(map, marker);
                    }, 2000);


                    //map.panTo(marker.getPosition()); 
                    //map.setZoom(6);
                    //iw.open(map, marker);
				});
				google.maps.event.addListener(iw, 'closeclick', function() {
                    closeAllInfoWindows();
                    map.fitBounds(bounds);
				});
            }
            
            //add locations
            getLocations();
			*/
		}



	}

	// Initialize each block on page load (front end).
	$(document).ready(function() {
		$('#acf-map-locations').each(function() {
			initializeBlock($(this));
		});

	});
	// Initialize dynamic block preview (editor).
	if (window.acf) {
		console.log("Window ACF")
		window.acf.addAction('render_block_preview', initializeBlock);
	}

})(jQuery);